(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

var _closest = require('./polyfils/closest');

var _closest2 = _interopRequireDefault(_closest);

var _matches = require('./polyfils/matches');

var _matches2 = _interopRequireDefault(_matches);

var _array = require('./polyfils/array');

var _array2 = _interopRequireDefault(_array);

var _header = require('./partials/header');

var _header2 = _interopRequireDefault(_header);

var _contactButton = require('./partials/contactButton');

var _contactButton2 = _interopRequireDefault(_contactButton);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

(0, _matches2.default)();
(0, _closest2.default)();
(0, _array2.default)();
(0, _header2.default)();
(0, _contactButton2.default)();

},{"./partials/contactButton":2,"./partials/header":3,"./polyfils/array":4,"./polyfils/closest":5,"./polyfils/matches":6}],2:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function () {
  function isInViewport(element) {
    var rect = element.getBoundingClientRect();
    return window.innerHeight - rect.top - 77 >= 0;
  }

  window.addEventListener('scroll', function () {
    var contactLink = document.querySelector('.js-contact-link');
    var windowHeight = window.innerHeight;
    var topBtn = document.querySelector('.js-top-btn');
    if (window.pageYOffset >= windowHeight / 2) {
      contactLink.classList.remove('visually-hidden');
      topBtn.style = 'bottom: 75px';
    } else {
      contactLink.classList.add('visually-hidden');
      topBtn.style = 'bottom: 20px';
    }

    var footer = document.querySelector('.footer-mobile');
    if (footer && isInViewport(footer)) {
      footer.classList.add('js-in-section');
      topBtn.style = 'bottom: 20px';
    } else {
      footer.classList.remove('js-in-section');
    }
  });
};

},{}],3:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function () {
  var header = document.querySelector('.js-white-header');
  function openMenu() {
    var btn = document.querySelector('.js-burger');
    var mobileMenu = document.querySelector('.js-mobile-menu');
    btn.addEventListener('click', function () {
      btn.classList.toggle('is-active');
      mobileMenu.classList.toggle('js-open');
      document.documentElement.classList.toggle('js-no-scroll');
    });
  }

  function changeStyleHeader() {
    var header = document.querySelector('.js-white-header');
    window.addEventListener('scroll', function () {
      var windowHeight = window.innerHeight;
      if (window.pageYOffset >= windowHeight) {
        header.classList.add('js-change');
      } else {
        header.classList.remove('js-change');
      }
    });
  }
  if (header) {
    openMenu();
    changeStyleHeader();
  }
};

},{}],4:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

/**
 * Array.from() polyfill
 */
// From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/from
// Production steps of ECMA-262, Edition 6, 22.1.2.1
exports.default = function () {

    if (!Array.from) {
        Array.from = function () {
            var toStr = Object.prototype.toString;
            var isCallable = function isCallable(fn) {
                return typeof fn === 'function' || toStr.call(fn) === '[object Function]';
            };
            var toInteger = function toInteger(value) {
                var number = Number(value);
                if (isNaN(number)) {
                    return 0;
                }
                if (number === 0 || !isFinite(number)) {
                    return number;
                }
                return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
            };
            var maxSafeInteger = Math.pow(2, 53) - 1;
            var toLength = function toLength(value) {
                var len = toInteger(value);
                return Math.min(Math.max(len, 0), maxSafeInteger);
            };

            // The length property of the from method is 1.
            return function from(arrayLike /*, mapFn, thisArg */) {
                // 1. Let C be the this value.
                var C = this;

                // 2. Let items be ToObject(arrayLike).
                var items = Object(arrayLike);

                // 3. ReturnIfAbrupt(items).
                if (arrayLike == null) {
                    throw new TypeError('Array.from requires an array-like object - not null or undefined');
                }

                // 4. If mapfn is undefined, then let mapping be false.
                var mapFn = arguments.length > 1 ? arguments[1] : void undefined;
                var T;
                if (typeof mapFn !== 'undefined') {
                    // 5. else
                    // 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
                    if (!isCallable(mapFn)) {
                        throw new TypeError('Array.from: when provided, the second argument must be a function');
                    }

                    // 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
                    if (arguments.length > 2) {
                        T = arguments[2];
                    }
                }

                // 10. Let lenValue be Get(items, "length").
                // 11. Let len be ToLength(lenValue).
                var len = toLength(items.length);

                // 13. If IsConstructor(C) is true, then
                // 13. a. Let A be the result of calling the [[Construct]] internal method
                // of C with an argument list containing the single item len.
                // 14. a. Else, Let A be ArrayCreate(len).
                var A = isCallable(C) ? Object(new C(len)) : new Array(len);

                // 16. Let k be 0.
                var k = 0;
                // 17. Repeat, while k < len… (also steps a - h)
                var kValue;
                while (k < len) {
                    kValue = items[k];
                    if (mapFn) {
                        A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
                    } else {
                        A[k] = kValue;
                    }
                    k += 1;
                }
                // 18. Let putStatus be Put(A, "length", len, true).
                A.length = len;
                // 20. Return A.
                return A;
            };
        }();
    }
};

},{}],5:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function () {
  if (!Element.prototype.closest) {
    if (!Element.prototype.matches) {
      Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
    }
    Element.prototype.closest = function (s) {
      var el = this;
      var ancestor = this;
      if (!document.documentElement.contains(el)) return null;
      do {
        if (ancestor.matches(s)) return ancestor;
        ancestor = ancestor.parentElement;
      } while (ancestor !== null);
      return null;
    };
  }
};

},{}],6:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

exports.default = function () {
    (function () {

        // проверяем поддержку
        if (!Element.prototype.matches) {

            // определяем свойство
            Element.prototype.matches = Element.prototype.matchesSelector || Element.prototype.webkitMatchesSelector || Element.prototype.mozMatchesSelector || Element.prototype.msMatchesSelector;
        }
    })();
};

},{}]},{},[1]);
